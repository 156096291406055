import React from "react";

type IconProps = {
    color: string;
    sx?: Partial<React.CSSProperties>;
    className: string;
};

function Logo({ color, sx, className }: IconProps) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="398" height="682" viewBox="0 0 398 682" style={sx}>
            <path fillRule="evenodd" clipRule="evenodd" d="M0 254.217H398V197.418H224.824V112.319L279.984 56.7323H398V0H264.726L199.304 65.8283L133.342 0H0V56.7997H118.016L173.716 112.387V197.485H0V254.217ZM0 356.968L46.0451 311.017H351.955L398 356.968V565.234H0V356.968ZM346.824 367.749H51.1763V508.502H346.824V367.749ZM172.77 682H0V625.268H172.77V682ZM398 682H227.39V625.268H398V682Z" fill={color} />
        </svg>
    );
}

export default Logo;
