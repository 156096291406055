import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import 'core-js';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import Head from 'next/head';
import { AnimatePresence } from 'framer-motion';
import Router from "next/router";
import Logo from "../public/images/svg/LogoIDK";
import Lenis from '@studio-freight/lenis';
import '../styles/globals.css';

function App({ Component, pageProps, router }: AppProps) {
  const [loading, setLoading] = useState<boolean>(true);

  const start = () => {
    setLoading(true);
  };

  const end = () => {
    setLoading(false);
  };

  // Simulate a delay for the loader to show
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []); // No dependencies, so it only runs once on mount

  useEffect(() => {
    window.scrollTo(0, 0);

    const lenis = new Lenis();

    function raf(time: number) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);

    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);

      lenis.destroy();
    };
  }, []); // No dependencies, so it only runs once on mount




  return (
    <React.StrictMode>
      <Script
        src="https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver"
        strategy="beforeInteractive"
      />
      <Head>
        <meta name="adopt-website-id" content="7f2f9e41-7eac-4067-a86d-2c80e03e5478" />
        <script src="//tag.goadopt.io/injector.js?website_code=7f2f9e41-7eac-4067-a86d-2c80e03e5478" class="adopt-injector"></script>
        <script async src="https://www.googletagmanager.com/gtm.js?id=GTM-W3KWK6L"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-W3KWK6L');`,
          }}
        />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-241449299-1" type="text/javascript"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-241449299-1');`,
          }}
        />
        <script src="https://www.googletagmanager.com/gtag/js?id=G-QW83E8ZQLS" type="text/javascript"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-QW83E8ZQLS');`,
          }}
        />
      </Head>
      <AnimatePresence
        mode="wait"
        initial={false}
        onExitComplete={() => window.scrollTo(0, 0)}
      >
        <main
          style={{
            fontFamily: "'Inter', sans-serif",
            background: '#D9D9D9'
          }}>
          <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W3KWK6L" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>

          <div className={`loader ${loading ? '' : 'active'}`}>
            <Logo className="glitch glitch-01" color="#39ff14" />
            <Logo className="glitch glitch-02" color="#F00" />
            <Logo className="glitch glitch-03" color="#daff00" />
            <Logo className="glitch glitch-04" color="#00F" />
            <Logo className="logo" color="#FFF" />
          </div>

          <Component {...pageProps} key={router.asPath} />
        </main>
      </AnimatePresence>
    </React.StrictMode>
  );
}

export default App;